import React from 'react'


import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Dashboard from './Components/Dashboard'
import AuthProvider from './Components/Contexts/AuthContext'
import Login from './Components/Authentication/Login'
import Signp from './Components/Authentication/Signp'
import ForgotPassword from './Components/Authentication/ForgorPassword'
import PrivateRoute from './Components/Authentication/PrivateRoute'
import Category from './Components/Category/Category'
import UpdateCategory from './Components/Category/UpdateCategory'
import Movie from './Components/Movie/Movie'
import UpdateMovie from './Components/Movie/UpdateMovie'
import Karoke from './Components/Karoke/Karoke'
import UpdateKaroke from './Components/Karoke/UpdateKaroke'

export default function App() {
    return (
        <Router>               
                <AuthProvider>
                    <Switch>

                        <Route exact path="/" component={Login} />
                        <Route path="/signup" component={Signp} />
                        <Route path="/forgot-password" component={ForgotPassword} />

                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        <PrivateRoute path="/category" component={Category} />
                        <PrivateRoute path="/update-category/:id" component={UpdateCategory} />

                        <PrivateRoute path="/movies" component={Movie} />
                        <PrivateRoute path="/update-movie/:id" component={UpdateMovie} />

                        <PrivateRoute path="/karokes" component={Karoke} />
                        <PrivateRoute path="/update-karoke/:id" component={UpdateKaroke} />
                        
                    </Switch>    
                </AuthProvider>    
                         
        </Router>
    )
}