import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Spinner, Alert, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';
import Header from '../includes/Header';
import Menu from '../includes/Menu';


export default function UpdateKaroke( { match } ) {

    const { apiUrl, currentUser } = useAuth();

    const [ loading, setLoading] = useState(false);
    const [ delLoading, setDelLoading ] = useState( false );
    const [ error, setError] = useState();
    const [ dataid, setDataid] = useState();
    const [ categorys, setCategorys] = useState([]);
    const [ selectedFile, setSelectedFile] = useState(null);
    const [ exist_image, setExistImage] = useState("");

    const categoryRef = useRef();
    const nameRef = useRef();
    const descriptionRef = useRef();
    const durationRef = useRef();

    const history = useHistory();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {

        bind_categorys();

    }, []);

    async function dispatch_data() {

        var id = match.params.id;
        setDataid(id);

        if( id !== 'new' ) {
            
        var formData = new FormData();
        formData.append('id', id );

        var url = apiUrl + "Karoke/Get";

        await Axios.post(url, formData, {
            'Content-Type': 'multipart/form-data'
        }).then( res => {

            if(res.data !== null) {

                categoryRef.current.value = res.data.category_id;
                nameRef.current.value = res.data.name;
                descriptionRef.current.value = res.data.description;
                durationRef.current.value = res.data.duration;
                setExistImage( res.data.image_url );

            } else {

            history.push('/karokes');
            }

        });
      }
    }

    async function bind_categorys() {

        var url = apiUrl + "Category/Search";

        var formData = new FormData();

        formData.append('email', currentUser.email)
        formData.append('search_key', '');

        await Axios.post(url, formData, {

        'Content-Type' : 'multipart/form-data'

        }).then(res => {
            
            setCategorys( res.data.datas );
            dispatch_data();
        });

        return false;
    }

    async function handleSubmit( e ) {

        e.preventDefault();

        var url = "";

        if(dataid === 'new') {
          url = apiUrl + "Karoke/Add";
        } else {
          url = apiUrl + "Karoke/Modify";
        }

        var formData = new FormData();

        formData.append('id', dataid);
        formData.append('primary_image', selectedFile )
        formData.append('email', currentUser.email);
        formData.append('category_id', categoryRef.current.value);
        formData.append('name' , nameRef.current.value );
        formData.append('description', descriptionRef.current.value);
        formData.append('duration', durationRef.current.value);
        formData.append('temp_file', exist_image);
        
        setLoading( true );

        await Axios.post(url, formData, {
          'Content-Type': 'multipart/form-data'

        }).then( res => {

            setLoading(false);

            if(!loading) {

              console.log( res.data );

              if(res.data.task === 0 ) {
                setError( res.data.message );

              } else {
                history.push('/karokes');
              }
            }
            
        })
    }

    async function handleDelete() {

        var url = apiUrl + "Karoke/Remove";

        var formData = new FormData();
        formData.append('id',  dataid);

        setDelLoading( true )
        await Axios.post(url, formData, {
          'Content-Type': 'multipart/form-data'
        }).then( res => {

            setDelLoading(false);

            if(res.data.task === 1) {
              history.push('/karokes')
            }
        });

    }

    const changeImage = (e) => {

        setSelectedFile(e.target.files[0]);

        var url = apiUrl + "App/Get_TMP_Name";
        var formData = new FormData();
        formData.append('primary_image', e.target.files[0]);

        Axios.post(url, formData, {
            'Content-Type': 'multipart/form-data'
        }).then( res => {

            setExistImage( res.data );
        });
    }

    return (
        <div>
             <Header />
             <Menu />

             <div className="content-wrapper" style={{backgroundColor:"#fff"}}>
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h5>Update Karoke</h5>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">Photofast</li>
            <li className="breadcrumb-item">Karoke</li>
            <li className="breadcrumb-item active">Update Karoke</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure delete this data ?</Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={handleClose}>
              Close
            </Button>
            {delLoading && <Button variant="danger"><Spinner animation="border" variant="light" /></Button> }
            {!delLoading && <Button variant="danger" onClick={ handleDelete }>Sure</Button> }
          </Modal.Footer>
        </Modal>

        {error && <Alert variant="danger">{error}</Alert>}
        <div className="card card-light">
        <div className="card-header">
        <h3 className="card-title">Karoke - { dataid !== 'new' ? dataid : 'New' }</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <form onSubmit={handleSubmit} role="form">
            <div className="card-body">
            <div className="row">
                <div className="col-lg-6">

                        <div className="form-group">
                                <label htmlFor="primary_image">Primary Image</label>
                                <input type="file" className="form-control" id="primary_image" name="primary_image" onChange={ changeImage } />
                        </div> 

                    <div className="form-group">
                        <img src={exist_image} width="120" height="100" />
                    </div>
                    
                        <div className="form-group">
                            <label>Select Category</label>
                            <select ref={categoryRef} className="form-control select2bs4" style={{width: '100%'}}>
                                {categorys.map(e => 
                                    <option key={e.id} value={e.id}>{e.name}</option>
                                )}                   
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="name">Album name</label>
                            <input ref={nameRef} type="text" className="form-control" id="name" placeholder="Enter name" required />
                        </div> 

                        <div className="form-group">
                            <label htmlFor="cast">Duration</label>
                            <input ref={durationRef} type="number" className="form-control" id="duration" placeholder="Enter duration" required />
                        </div> 
                        

                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <textarea ref={descriptionRef} type="text" className="form-control" id="description" rows="7">
                                 
                            </textarea>
                        </div> 

                </div>
            </div>
            
            </div>

            {loading && <Button className="btn btn-primary ml-4" type="button" ><Spinner animation="border" variant="light" /></Button>}
            {!loading && <Button className="btn btn-primary ml-4" type="submit" >Save</Button> }

            { dataid !== 'new' ? <Button className="btn btn-danger ml-2" type="button" onClick={handleShow} >Delete</Button> : null }

            <a href="/karokes" className="btn btn-light ml-2">Back To List</a>

        </form>
        </div>


    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>
{/* /.content-wrapper */}

        </div>
    )
}
