import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';


export default function Header() {

    const [error, setError] = useState('');
    const { currentUser, logout, currentShiftId, currentUserId } = useAuth()

    const history = useHistory();

    async function handleLogout() {

        setError('');

        try{
            await logout();

            history.push('/')
        } catch {
            setError('Failed to logout')
        }
    }

    return (
        <div>
            
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" />&nbsp;&nbsp;&nbsp;SHOP NAME</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button">UserID : {currentUserId}</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button">Email : {currentUser.email}</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button">Shiftno : {currentShiftId}</a>
                    </li>
                </ul>

                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">
                    
                    {/* Notifications Dropdown Menu */}
                    <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#">
                        <i className="far fa-bell" />
                        <span className="badge badge-warning navbar-badge">15</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <span className="dropdown-item dropdown-header">15 Notifications</span>
                        <div className="dropdown-divider" />
                        <a href="#" className="dropdown-item">
                        <i className="fas fa-envelope mr-2" /> 4 new messages
                        <span className="float-right text-muted text-sm">3 mins</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a href="#" className="dropdown-item">
                        <i className="fas fa-users mr-2" /> 8 friend requests
                        <span className="float-right text-muted text-sm">12 hours</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a href="#" className="dropdown-item">
                        <i className="fas fa-file mr-2" /> 3 new reports
                        <span className="float-right text-muted text-sm">2 days</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
                    </div>
                    </li>
                    <li className="nav-item dropdown">
                        <Button className="btn btn-light ml-3" onClick={handleLogout} >
                            <i className="fas fa-power-off" /> Logout
                        </Button>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

