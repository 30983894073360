import Axios from 'axios';
import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../../firebase'

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export default function AuthProvider({ children }) {

    const [ currentUser, setCurrentUser ] = useState()
    const [ currentShiftId, setCurrentShift ] = useState()
    const [ currentUserId, setCurrentUserId ] = useState()
    const [ loading, setLoading] = useState(true)
    const [ apiUrl , setApiUrl ] = useState()

    function signup( email, password) {
        return auth.createUserWithEmailAndPassword(email, password);
    }

    function login ( email, password ) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    function logout() {
        return auth.signOut();
    }

    function resetPassword( email ) {
        return auth.sendPasswordResetEmail( email );
    }

    function changePassword( password ) {
        return auth.updatePassword( password );
    }

    function setUserData( id, shift_id ) {

        setCurrentUserId( id );
        setCurrentShift( shift_id  );

        return true;
    }

    useEffect(() => {

        var api_url = 'https://admin.photofast.co/movie_api/'
        setApiUrl(api_url);

        const unsubcriber = auth.onAuthStateChanged(user => {

            setCurrentUser( user );
            setLoading(false);

            if(user === null) {
                console.log('User not found');
                return;
            }

            var url = api_url + 'Authentication/Get_Login_Data';
            var formData = new FormData();

            formData.append("email" , user.email);

            Axios.post(url, formData, {
                "Content-Type": 'multipart/form-data'
            }).then(res => {
                if(res.data !== null) {

                    setUserData( res.data.user_id, res.data.shift_id )

                } else  {

                    console.log('Data not found');
                }
                
            });
            
        });

        return unsubcriber;

    }, [])

    const value = {
        
        currentUser,
        signup,
        login,
        logout,
        resetPassword,
        changePassword,
        setUserData,
        currentUserId,
        currentShiftId,
        apiUrl,
    }

    return (
        
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
