import { Button } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../Contexts/AuthContext';
import Header from '../includes/Header'
import Menu from '../includes/Menu'
import Axios from 'axios';

export default function Movie() {


  const [ movies , setMovies] = useState([]);
  const { apiUrl , currentUser } = useAuth()

  const searchRef = useRef();

  useEffect(() => {

    dispatch_data('', '');
    
  }, []);

  async function dispatch_data( search_key ) {

    var url = apiUrl + "Movie/Search";

    var formData = new FormData();

    formData.append('email', currentUser.email)
    formData.append('search_key', search_key);

    await Axios.post(url, formData, {

      'Content-Type' : 'multipart/form-data'

    }).then(res => {

        console.log(res.data);
        setMovies( res.data.datas );
    })
  }


  const search_movies = () => {

      dispatch_data( searchRef.current.value );
  }

    return (
      <div>
      <Header />
      <Menu />

<div className="content-wrapper" style={{backgroundColor:"#fff"}}>
{/* Content Header (Page header) */}
<section className="content-header">
<div className="container-fluid">
<div className="row mb-2">
  <div className="col-sm-6">
    <h1>Movies</h1>
  </div>
  <div className="col-sm-6">
    <ol className="breadcrumb float-sm-right">
      <li className="breadcrumb-item">Photofast</li>
      <li className="breadcrumb-item active">Products</li>
    </ol>
  </div>
</div>
</div>{/* /.container-fluid */}
</section>
{/* Main content */}
<section className="content">
<div className="container-fluid">

<div className="row">
  <div className="col-12">
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">List</h3>
        
        <div className="card-tools">
          <div className="input-group input-group-sm" style={{width: 400}}>
            <a href="/update-movie/new" className="btn btn-primary btn-sm mr-2"> Add New </a>


            <input type="text" ref={searchRef} name="table_search" className="form-control float-right" placeholder="Search" onChange={search_movies} />
            <div className="input-group-append">
              <button type="submit" className="btn btn-default"><i className="fas fa-search" /></button>

            </div>
            
          </div>
        </div>
      </div>
      {/* /.card-header */}
      <div className="card-body table-responsive p-0">
        <table className="table table-hover table-bordered text-nowrap">
          <thead>
            <tr>
              <th style={{ width: "10%" }}>ID</th>
              <th style={{ width: "10%" }}>Image</th>
              <th style={{ width: "10%" }}>Category</th>
              <th style={{ width: "15%" }}>Name</th>
              <th style={{ width: "20%" }}>Cast</th>     
              <th>Description</th>        
              <th style={{ width: "15%" }}>LastUpdate</th>
            </tr>
          </thead>
          <tbody>
            {movies.map( e => 
              <tr key={e.id}>
                <td><a href = {"update-movie/"+ e.id }>{e.id}</a></td>
                <td><img src={e.image_url} width="120" height="100"/></td>
                <td>{e.category_name}</td>
                <td>{e.name}</td>
                <td>{e.cast}</td>        
                <td>{e.description}</td>  
                <td>{e.update_on}</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="row" style={{width:"99%"}}>

            <div className="col-md-6">
                <p className="mt-2 ml-2">Showing 1 to 10 of 10 Records</p>
            </div>

            <div className="col-md-6" style={{textAlign:"right"}}>
              <div className="mt-1">
                  <Button variant="light" className="ml-2"><i className="fas fa-angle-double-left" /></Button>
                  <Button variant="light" className="ml-2" onClick=""><i className="fas fa-angle-left" /></Button>
                  <Button variant="light" className="ml-2" onClick=""><i className="fas fa-angle-right" /></Button>
                  <Button variant="light" className="ml-2"><i className="fas fa-angle-double-right" /></Button>
              </div>               
            </div>
        </div>
      </div>
      {/* /.card-body */}
    </div>
    {/* /.card */}
  </div>
</div>

</div>{/* /.container-fluid */}
</section>
{/* /.content */}
</div>
{/* /.content-wrapper */}




  </div>
    )
}
