import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Spinner, Alert, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';
import Header from '../includes/Header';
import Menu from '../includes/Menu';


export default function UpdateCategory( { match } ) {

    const { apiUrl, currentUser } = useAuth();

    const [ loading, setLoading] = useState(false);
    const [ delLoading, setDelLoading ] = useState( false );
    const [ error, setError] = useState();
    const [ dataid, setDataid] = useState();

    const nameRef = useRef();

    const history = useHistory();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {

        var id = match.params.id;
        setDataid(id);

        if( id !== 'new' ) {
            
          var formData = new FormData();
          formData.append('id', id );

          var url = apiUrl + "Category/Get";

          Axios.post(url, formData, {
            'Content-Type': 'multipart/form-data'
          }).then( res => {

            if(res.data !== null) {

                nameRef.current.value = res.data.name;

            } else {

              history.push('/category');
            }

          });

        } else { 
          console.log('this is new');
        }

    }, []);

    async function handleSubmit( e ) {

        e.preventDefault();

        var url = "";

        if(dataid === 'new') {
          url = apiUrl + "Category/Add";
        } else {
          url = apiUrl + "Category/Modify";
        }

        var formData = new FormData();

        formData.append('id', dataid);
        formData.append('email', currentUser.email);
        formData.append('name' , nameRef.current.value );

        setLoading( true );

        await Axios.post(url, formData, {
          'Content-Type': 'multipart/form-data'

        }).then( res => {

            setLoading(false);

            if(!loading) {

              console.log( res.data );

              if(res.data.task === 0 ) {

                setError( res.data.message );

              } else {

                history.push('/category');

              }
            }
            
        })
    }

    async function handleDelete() {

        var url = apiUrl + "Movie/Remove";

        var formData = new FormData();
        formData.append('id',  dataid);

        setDelLoading( true )
        await Axios.post(url, formData, {
          'Content-Type': 'multipart/form-data'
        }).then( res => {

            setDelLoading(false);

            if(res.data.task === 1) {
              history.push('/movies')
            }
        });

    }

    return (
        <div>
             <Header />
             <Menu />

             <div className="content-wrapper" style={{backgroundColor:"#fff"}}>
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h5>Add Product Types</h5>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">Photofast</li>
            <li className="breadcrumb-item">Category</li>
            <li className="breadcrumb-item active">Update Category</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure delete this data ?</Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={handleClose}>
              Close
            </Button>
            {delLoading && <Button variant="danger"><Spinner animation="border" variant="light" /></Button> }
            {!delLoading && <Button variant="danger" onClick={ handleDelete }>Sure</Button> }
          </Modal.Footer>
        </Modal>

        {error && <Alert variant="danger">{error}</Alert>}
        <div className="card card-light">
        <div className="card-header">
        <h3 className="card-title">Category - { dataid !== 'new' ? dataid : 'New' }</h3>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <form onSubmit={handleSubmit} role="form">
            <div className="card-body">
            <div className="row">
                <div className="col-lg-6">


                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input ref={nameRef} type="text" className="form-control" id="name" placeholder="Enter name" required />
                        </div> 


                </div>
            </div>
            
            </div>

            {loading && <Button className="btn btn-primary ml-4" type="button" ><Spinner animation="border" variant="light" /></Button>}
            {!loading && <Button className="btn btn-primary ml-4" type="submit" >Save</Button> }

            { dataid !== 'new' ? <Button className="btn btn-danger ml-2" type="button" onClick={handleShow} >Delete</Button> : null }

            <a href="/category" className="btn btn-light ml-2">Back To List</a>

        </form>
        </div>


    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>
{/* /.content-wrapper */}

        </div>
    )
}
