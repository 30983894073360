import React, { useRef, useState } from 'react'
import {Form, Button, Card, Alert, Spinner, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext'

export default function ForgotPassword() {

    const emailRef = useRef();
    const { resetPassword } = useAuth()
    const [ message, setMessage] = useState("");
    const [ error, setError] = useState("");
    const [ loading, setLoading] = useState(false);

    async function handleSubmit(e) {

        e.preventDefault();

        try{

            setMessage('');
            setError('');
            setLoading(true);

            await resetPassword(
                emailRef.current.value,
            )

            setMessage('Check your inbox for further informations');
            
        }catch{
            setError('Invalid email address !');
        }

        setLoading(false);
       
    }

    return (
        <>
            <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}} >
            <div className="w-100" style={{maxWidth: '400px'}}>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Forgot Password</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {message && <Alert variant="success">{message}</Alert>}
                    <Form  onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>

                        {loading && <Button className="w-100" type="button" ><Spinner animation="border" variant="light" /></Button>}
                        {!loading && <Button className="w-100" type="submit" >Reset Password</Button>}

                    </Form>
                    <div className="w-100 text-center mt-3">
                        <Link to="/">Back</Link>
                    </div>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Need an account ? <Link to="/signup">Sign Up</Link>
            </div>
            </div>
            </Container>
        </>
    );
}

