import Axios from 'axios';
import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert, Spinner, Container } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext'

export default function Signp() {

    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { signup, apiUrl } = useAuth();
    const [ error, setError] = useState();
    const [ message, setMessage] = useState();
    const [ loading, setLoading] = useState(false);

    async function handleSubmit(e) {

        e.preventDefault();

        if(passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Password do not match')
        }

        try{

            setError('')
            setLoading(true)
            await signup(

                emailRef.current.value,
                passwordRef.current.value
            )
            
            handleCreateUser();
            
        }catch{

            setError('Failed to create an account')
        }

        setLoading(false);
       
    }

    async function handleCreateUser( ) {
        
        var formData = new FormData();
        formData.append("email", emailRef.current.value);

        var url = apiUrl + 'Authentication/Add_User';

        Axios.post(url, formData, {
            headers:{
                'Content-Type' : 'multipart/form-data'
            }
        }).then( res => {

                setMessage( ' Account successfully created. Please activate your account via Administrator ' );
            
        });
    }

    return (
        <>
         <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}} >
            <div className="w-100" style={{maxWidth: '400px'}}>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Sign Up</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {message && <Alert variant="success">{message}</Alert>}
                    <Form  onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>

                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>

                        <Form.Group id="password-confirm">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmRef} required />
                        </Form.Group>

                        {loading && <Button className="w-100" type="button" ><Spinner animation="border" variant="light" /></Button>}
                        {!loading && <Button className="w-100" type="submit" >Create Account</Button>}

                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Already have an account ? <Link to="/">Log In</Link>
            </div>
            </div>
            </Container>
        </>
    );
}
