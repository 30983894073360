import Axios from 'axios';
import React, { useRef, useState } from 'react'
import {Container, Form, Button, Card, Alert, Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext'

export default function Login() {

    const emailRef = useRef();
    const passwordRef = useRef();
    const { apiUrl, login, logout } = useAuth()
    const [ error, setError] = useState();
    const [ loading, setLoading] = useState(false);
    const history = useHistory();

    async function handleSubmit(e) {

        e.preventDefault();

        try{

            setError('')
            setLoading(true)
            await login(
                
                emailRef.current.value,
                passwordRef.current.value
            )

            handleSetUserId();

        }catch{
            setError('Inavalid user credentials !');
        }

        setLoading(false);
       
    }

    async function handleSetUserId() {

        var formData = new FormData();

        formData.append('email', emailRef.current.value );

        var url = apiUrl+'Authentication/Get_User_Data';

        Axios.post(url , formData, {
            headers : {
                'Content-Type' : 'multipart/form-data'
            }
        }).then(res => {

            if(res.data.user_id === "none") {

                logout();
                setError('Your account has not activate. Please contact your Administrator.');

            } else {

                history.push('/dashboard');
            }
        })
    }

    return (
        <>
            <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}} >
            <div className="w-100" style={{maxWidth: '400px'}}>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Login</h2>
                    {error && <Alert variant="danger">{error}</Alert>}

                    <Form  onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>

                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>

                        {loading && <Button className="w-100" type="button" ><Spinner animation="border" variant="light" /></Button>}
                        {!loading && <Button className="w-100" type="submit" >Login</Button>}

                    </Form>
                    <div className="w-100 text-center mt-3">
                        <Link to="/forgot-password">Forgot Password ?</Link>
                    </div>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Need an account ? <Link to="/signup">Sign Up</Link>
            </div>
            </div>
            </Container>
            
        </>
    );
}
