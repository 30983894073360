import React from 'react'
import { useAuth } from '../Contexts/AuthContext'


export default function Menu() {

  const { currentUser, currentShiftId } = useAuth();

    return (
        <div>
           {/* Main Sidebar Container */}
<aside className="main-sidebar sidebar-light-primary elevation-4">
  {/* Brand Logo */}
  <a href="/" className="brand-link">
    <span className="brand-text font-weight-light ml-4">PhotoFast</span>
  </a>
  {/* Sidebar */}
  <div className="sidebar">
    {/* Sidebar user panel (optional) */}
    
    {/* Sidebar Menu */}
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li className="nav-item has-treeview">
          <a href="/category" className="nav-link">
            <i className="nav-icon fas fa-copy" />
            <p>
              Category
              <i className="fas fa-angle-right right" />
            </p>
          </a>
        </li>

        <li className="nav-item has-treeview">
          <a href="/movies" className="nav-link">
            <i className="nav-icon fas fa-film" />
            <p>
              Movies
              <i className="fas fa-angle-right right" />
            </p>
          </a>
        </li>

        <li className="nav-item has-treeview">
          <a href="/karokes" className="nav-link">
            <i className="nav-icon fas fa-microphone" />
            <p>
              Karoke
              <i className="fas fa-angle-right right" />
            </p>
          </a>
        </li>


       <li className="nav-item has-treeview">
          <a href="/events" className="nav-link">
            <i className="nav-icon fas fa-edit" />
            <p>
              Events
              <i className="right fas fa-angle-right" />
            </p>
          </a>
        </li>

        <li className="nav-item has-treeview">
          <a href="/photography" className="nav-link">
            <i className="nav-icon fas fa-image" />
            <p>
              Photography
              <i className="right fas fa-angle-right" />
            </p>
          </a>
        </li>

        <li className="nav-item has-treeview">
          <a href="/settings" className="nav-link">
            <i className="nav-icon fas fa-wrench" />
            <p>
              Settings
              <i className="right fas fa-angle-right" />
            </p>
          </a>
        </li>

      </ul>
    </nav>
    {/* /.sidebar-menu */}
  </div>
  {/* /.sidebar */}
</aside>

        </div>
    )
}
